export const context = {
  login: "",
  password: "",
  showPassword: false,
  errorForm: false,
  errorMessage: "",
  typeUser: false,
  showNavigationItemDiscipline: false,
  valueNavigationItemDiscipline: "",
  showNavigationItemGroup: false,
  valueNavigationItemGroup: "",
  valueNavigationItemStudent: "",
  openMenu: false,
  showFormItem: false,
  nameDiscipline: "",
  nameGroup: "",
  nameStudent: "",
  showIconDeleteItems: false,
  getGroups: false,
  showNameGroup: false,
  showSurNameStudent: false,
  disabledDiscipline: true,
  checkedRadioDiscipline: false,
  checkedRadioGroup: false,
  checkedRadioStudent: false,
  showRadioButtons: true,
  upLoadFileButton: true,
  showListItems: true,
  showListStudents: false,
  getListDates: false,
  isActiveMoodle: false,
  isActiveSearchStudent: false,
  isShowSearchStudent: false,
  showNavigationItemStudent: false,
  isSwitchMenuNavigationItemGroup: false,
  isActiveListItems: true,
  titleNameGroup: "",
  update: false,
  uploadFile: false,
  isShowColumns: true,
};
